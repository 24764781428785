
import SellableFilter from '@/components/sellable_filter'
import { as_json, number_to_currency } from '@/lib/helpers'
import LoadingSpinner from '@/components/loading_spinner'
import OfferPickInput from '@/components/offer_pick_input'

export default {
  components: {
    OfferPickInput,
    SellableFilter,
    LoadingSpinner
  },

  computed: {
    loading: ->
      @$store.state.product.loading
    ,
    market_application: ->
      !@$store.getters['application/is_agency']
    ,
    current_offer: ->
      @$store.getters['offers/supplier_offer']
    ,
    current_offer_cart_qty: ->
      @current_offer && @$store.getters['cart/offer_qty'](@current_offer.id)
    ,
    best_offer: ->
      @$store.getters['offers/with_cart_qty'][0]
    ,
    offers: ->
      @$store.getters['offers/with_cart_qty']
    ,
    offers_loading: ->
      @$store.state.offers.loading
    ,
    product: ->
      @$store.state.product
    ,
    name: ->
      @$store.state.product.name
    ,
    sellable: ->
      @$store.state.sellable_filter.result
    ,
    show_offers_list: ->
      !@$store.getters['suppliers/current']
  },

  watch: {
    sellable: ->
      !!@sellable.id && @$store.dispatch("offers/fetch_for", @sellable.id)
  },

  methods: {
    show_add_to_cart_modal: (offer) ->
      @$store.commit('offers/set_current', offer)

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })

    cart_offer_total_in_cents: (offer_id) ->
      @$store.getters['cart/offer_total_in_cents'](offer_id)

    set_page_title: ->
      @$store.commit('layout/page_nav', {title: @name})
      document.title = @product.name
      @$store.dispatch('analytics/page_view')
  },

  created: ->
    @$store.commit('layout/page_nav', {title: @name})
    @$store.dispatch("product/fetch", @$route.params)
      .finally => @set_page_title()

  destroyed: ->
    @$store.commit('offers/reset')
}
